<template>
  <article class="taycan">
    <Hero
      heading="Charge up fast with Electrify Canada"
      subheading="Enjoy Ultra-Fast DC charging for your Porsche Taycan"
    >
      <template #image
        ><img
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_1_EN.jpg')" /></template
    ></Hero>

    <TwoColumnContentBlock heading="Charging on the road just got more convenient.">
      <p>
        The first all-electric Porsche is fully equipped to take advantage of the 350 kW DC fast chargers from Electrify
        Canada. That means you can recharge your Porsche Taycan at Electrify Canada stations from 5% to 80% in as few as
        22.5 minutes
        <reference-link num="1">{{ $t('chargingTimesMayVary') }}</reference-link>
        – the fastest charging in today’s automotive market.
        <reference-link num="2">As of April 30, 2020.</reference-link>
        Electrify Canada has identified 32 site locations, offering 128 charging stations, with more to follow across 4
        Canadian Provinces. These locations are along major highway routes and within well-traveled metro areas.
      </p>
      <p>
        What’s more, all Canadian Porsche Taycan owners are eligible to receive three years of complimentary 30-minute
        charging sessions on the Electrify Canada high-powered charging network.
        <reference-link num="3"
          >Applies to Porsche Taycan owners only who purchase a new Porsche Taycan from an authorized Canadian Porsche
          dealership. Program offer applies to Porsche Taycan vehicles purchased on or before December 31, 2020,
          effective until December 31st 2023. For a Porsche Taycan purchased on or after January 01, 2021, program is
          effective for thirty-six (36) months from purchase date. The first 30 minutes of every charge is
          complimentary. Sixty (60) minutes must elapse between complimentary sessions. Taxes and idling fees are not
          included. Learn more
          <a href="https://www.electrify-canada.ca/faqs">https://www.electrify-canada.ca/faqs</a></reference-link
        >
        With complimentary charging that’s both fast and convenient, you’ll be back on the road in no time to enjoy the
        incredible performance and refinement of your Porsche Taycan.
      </p>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="Right from your phone.">
      <template #image
        ><img
          loading="lazy"
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_2_RT_EN.jpg')"
      /></template>
      <p>
        The Porsche Charging Service app (and Porsche Connect app) provides access to three years of unlimited 30 minute
        complimentary charging on the Electrify Canada network.
      </p>
      <ul>
        <li>
          <strong>Step 1:</strong>
          <a rel="noopener" target="_blank" href="https://connect-portal.porsche.com/core/ca/en_US/home">
            Activate Porsche Connect Services</a
          >
        </li>
        <li><strong>Step 2:</strong> Enroll in the Porsche Charging Service.</li>
        <li><strong>Step 3:</strong> Download the Porsche Charging Service App</li>
        <li><strong>Step 4:</strong> Locate a charging station</li>
        <li><strong>Step 5:</strong> Plug-in and start your charging session</li>
      </ul>
      <router-link :to="{ name: 'locate-charger-en' }" class="button">LOCATE A CHARGER</router-link>
    </TwoColumnContentBlock>

    <TwoColumnContentBlock heading="A new and innovative network">
      <template #image>
        <img
          loading="lazy"
          alt=""
          :src="require('@/assets/images/PorscheTaycan/19_87_957_Porsche_Landing_Page_Image_3_EN.jpg')"
      /></template>
      <p>
        Experience fast charging as it was meant to be with 350 kW charging stations from Electrify Canada. This
        convenient and reliable network of high-powered chargers is being constructed in well-traveled metropolitan
        areas and along busy Canadian highways. Each location will have a minimum of one 350 kW charger, with additional
        chargers delivering up to 150 kW to capable vehicles. With their slim and tall design, ample night-time
        illumination and first-of-its-kind liquid-cooled cables, Electrify Canada fast charging is easier than ever. In
        addition, dual cables at each location make it even more convenient to charge, no matter where your EV’s charge
        port is located. Find out more about Electrify Canada’s new network, built to give Canadians the speed and
        reliability they need.
      </p>
      <router-link :to="{ name: 'faqs-en' }" class="button">LEARN MORE</router-link>
    </TwoColumnContentBlock>
  </article>
</template>

<script>
import lang from '@/lang';
import ReferenceLink from '@/components/ReferenceLink.vue';
import Hero from '@/components/Hero/Hero.vue';
import TwoColumnContentBlock from '@/components/TwoColumnContentBlock/TwoColumnContentBlock.vue';

export default {
  name: 'PorscheTaycan',
  metaInfo: {
    title: 'Electrify Canada Porsche Taycan Ultra-Fast Charging Program',
    meta: [
      {
        name: 'description',
        content: 'Electrify Canada Porsche Taycan Ultra-Fast Charging Program description and App sign-up information.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-canada.ca/taycan/' }],
  },
  components: { ReferenceLink, TwoColumnContentBlock, Hero },
  i18n: {
    messages: {},
    sharedMessages: lang,
  },
};
</script>
